import { Fragment, useState } from "react";
import { Form, Input, notification } from "antd";
import { Dialog, Transition } from "@headlessui/react";
import api from "../helpers/api";
import { useNavigate } from "react-router-dom";
import Cross from "../img/close-line.png";
import PropTypes from "prop-types";
import Loader from "./Loader";

const VerifyOTP = ({
  onCancel,
  visible,
  details,
}: {
  onCancel: () => void;
  visible: boolean;
  details: {
    pin: string;
    new_pin: string;
    confirm_pin: string;
  };
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const close = () => {
    form.resetFields();
    onCancel();
  };

  const submit = async () => {
    setLoading(true);
    const data = { ...details, otp };
    console.log(data);
    const response = await api.post("/v2/auth/reset-password", data);
    console.log(response);
    if (response.data.status === 200 || response.data.status === 201) {
      setLoading(false);
      notification.success({ message: "PIN updated" });
      close();
      navigate("/security-details");
    } else {
      notification.error({ message: "Could not updated PIN" });
      setLoading(false);
      close();
    }
  };

  return (
    <>
      <Transition appear show={visible} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"
              onClick={close}
            />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="relative text-left p-4 sm:p-6 md:p-10 bg-oya-ghana-modal-background border border-oya-ghana-border shadow-form rounded-xl">
                  <div className="flex items-start justify-between">
                    <div className="flex items-start">
                      <img src="/assets/img/login/reset.png" alt="" />
                      <h3 className="text-oya-ghana-header-h4 text-oya-ghana-black-1 font-bold pb-5 pl-4">
                        Two-Factor <br className="hidden sm:block" />{" "}
                        Authentication
                      </h3>
                    </div>

                    <div
                      className="border-2 border-oya-ghana-border p-2 rounded-full cursor-pointer ml-4"
                      onClick={close}
                    >
                      <img
                        src={Cross}
                        alt="close button"
                        style={{ width: 20, height: 20 }}
                      />
                    </div>
                  </div>

                  <Form
                    className="w-full"
                    form={form}
                    layout="vertical"
                    onFinish={submit}
                  >
                    <Form.Item
                      colon={false}
                      name="otp"
                      label={
                        <div className="text-gray-500 text-sm">
                          OTP Sent To Your Number
                        </div>
                      }
                      className="w-full my-6"
                      rules={[
                        { required: true, message: "This field is required" },
                      ]}
                    >
                      <Input.Password
                        type="text"
                        autoComplete="off"
                        placeholder="123456"
                        size="large"
                        onChange={(e) => setOtp(e.target.value)}
                        disabled={loading}
                        className="w-full px-4 mt-2 bg-white border border-gray-200 outline-none rounded-md h-12"
                      />
                    </Form.Item>

                    <div className="flex justify-center mx-auto">
                      <button
                        className="text-xs mx-auto"
                        disabled={loading}
                        // onClick={props.resend}
                      >
                        Didn&apos;t receive OTP? Resend
                      </button>
                    </div>

                    <button
                      data-testid="update-pin-submit-button"
                      className="relative w-full trans flex items-center justify-center mt-2 mb-5 bg-oya-ghana-green text-oya-ghana-header-h6 rounded-md py-2 text-white h-12"
                      disabled={loading}
                    >
                      {loading && <Loader />}
                      Submit
                    </button>
                  </Form>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

VerifyOTP.propTypes = {
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  handleProceed: PropTypes.func,
  submitting: PropTypes.bool,
  resend: PropTypes.func,
};

export default VerifyOTP;
