import { useState } from "react";
import Undraw from "../../img/undraw_prioritise.png";
import { useLocation } from "react-router-dom";
import { API_URL, RouteConstants } from "../../helpers/constants";
// import { redirect } from "../../helpers/utils";
import { setCookie, setCookieJson } from "../../helpers/utils";
import { getDaysUntilJwtExpiration } from "../../helpers/utils";

import { Form, notification } from "antd";
import Loader from "../../components/Loader";

import PhoneInput, { Value } from "react-phone-number-input";
import { parse } from "querystring";
import axios from "axios";
import VerifyPhone from "./VerifyPhone";

const EmergencyNum = () => {
  const [emergencyContacts, setEmergencyContacts] = useState({
    ice_1: undefined as Value | undefined,
    ice_2: undefined as Value | undefined,
  });
  const location = useLocation();
  const redirect_url = parse(location.search.replace("?", ""));

  const [verifyPhone, setVerifyPhone] = useState<boolean>(false);

  const { first_name, last_name, phone, pin } = location.state?.data;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    axios
      .post(`${API_URL}${RouteConstants.REGISTER}`, {
        first_name,
        last_name,
        country_code: location.state?.code,
        phone,
        pin,
        ice1_phone: emergencyContacts.ice_1,
        ice2_phone: emergencyContacts.ice_2,
      })
      .then(async (data: any) => {
        console.log(data);

        const daysUntilExpiration = getDaysUntilJwtExpiration(
          data?.data?.payload?.token
        );

        setCookieJson("usr", data?.data?.payload?.user?.id);
        setCookie("token", data?.data?.payload?.token, daysUntilExpiration);
        setCookieJson("user_info", data?.data?.payload?.user);
        setCookieJson("user", {
          // ...data?.payload?.user,

          user_roles: [],
          role_id: data?.data?.payload?.user?.type?.id,
          role: data?.data?.payload?.user?.type?.name,
        });

        setVerifyPhone(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);

        notification.error({
          message: error?.response?.data?.message,
        });
      });
  };

  //console.log(location.state);
  return (
    <div className="max-w-6xl mx-auto">
      <div className="mb-8 sm:mb-12 md:mb-16 pt-24">
        <div className="flex items-start flex-wrap mb-20 md:gap-0 py-8 sm:pb-12 md:pb-16">
          <div className="w-full sm:w-1/2 px-4 sm:px-8">
            <div className="login-container p-4 md:p-10 border border-gray-200 shadow-form rounded-xl">
              <div className="text-left">
                <div className=" text-2xl pt-8 lg:w-[316px]">
                  Add emergency contact
                </div>
                <p className=" text-sm pt-4 lg:w-[260px]">
                  In case of any emergency please provide phone numbers of 2
                  people we can contact.
                </p>
              </div>
              <Form
                className="pt-2"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
              >
                <Form.Item
                  label={
                    <label
                      htmlFor="phone1"
                      className="block capitalize text-sm text-gray-601"
                    >
                      emergency contact 1
                    </label>
                  }
                  className="w-full mb-6 pr-3"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  colon={false}
                  required={true}
                >
                  <PhoneInput
                    name="phone1"
                    className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                    data-testid="register-phone-input"
                    defaultCountry="GH"
                    value={emergencyContacts.ice_1}
                    disabled={loading}
                    onChange={(e) =>
                      setEmergencyContacts((prevData) => ({
                        ...prevData,
                        ice_1: e,
                      }))
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <label
                      htmlFor="phone2"
                      className="block capitalize text-sm text-gray-601"
                    >
                      emergency contact 2
                    </label>
                  }
                  className="w-full mb-6 pr-3"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  colon={false}
                  required={true}
                >
                  <PhoneInput
                    name="phone2"
                    className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                    data-testid="register-phone-input"
                    defaultCountry="GH"
                    value={emergencyContacts.ice_2}
                    disabled={loading}
                    onChange={(e) =>
                      setEmergencyContacts((prevData) => ({
                        ...prevData,
                        ice_2: e,
                      }))
                    }
                  />
                </Form.Item>

                <button
                  data-testid="register-button"
                  type="submit"
                  disabled={loading}
                  className="flex w-full justify-center rounded-md border border-transparent bg-red-101 py-2 px-4 text-xl font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-101 focus:ring-offset-2"
                >
                  {loading && <Loader />}
                  Continue
                </button>
              </Form>
            </div>
          </div>
          <div className="hidden sm:block w-full sm:w-1/2">
            <div className="sm:pl-10 md:pl-16 lg:pl-24">
              <img
                src={Undraw}
                alt="Secure sign-in"
                className="md:w-[320px] h-[auto] max-[300px]:w-full"
              />
            </div>
            <div className="max-[300px]:w-[350px] pt-8 font-bold text-3xl">
              Get Started in one, two, three ...
            </div>
            <p className="max-[300px]:w-[350px] pt-4 text-base opacity-60">
              A journey by road allows for a greater sense of connection! Your
              senses record the sights, the breeze and the sheer ambiance only
              to be played back as memories!
            </p>
          </div>
        </div>
      </div>
      <VerifyPhone
        isOpen={verifyPhone}
        handleClose={() => {
          setVerifyPhone(false);
        }}
        phone={phone}
        redirect_url={redirect_url}
      />
    </div>
  );
};

export default EmergencyNum;
