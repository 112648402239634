import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Affix } from "antd";

type Props = {
  children: React.ReactNode;
  title: string;
  subTitle: string;
};

const MainLayout = (props: Props) => {
  let activeStyle = {
    color: "#000",
    fontWeight: "600",
  };

  return (
    <section className="max-w-6xl mx-auto ">
      <div className="flex items-start flex-wrap sm:py-6 lg:py-10 h-full">
        <Affix offsetTop={72} className="w-1/4 h-full">
          <div>
            <div className=" sm:mt-20 ml-8 mb-8 hidden sm:block">
              <div className="sm:text-oya-ghana-header-h3 leading-[36px] text-oya-ghana-header-h4 font-bold">
                {props.title}
              </div>
              <div className="sm:text-oya-ghana-body-normal-large text-oya-ghana-body-normal text-oya-ghana-faded-gray">
                {props.subTitle}
              </div>
            </div>
            <div className="hidden sm:block h-[60vh] overflow-auto pb-5 ml-8 border-r-2">
              <div className="sm:block hidden">
                <ul>
                  <li>
                    <NavLink
                      to="/account"
                      className="flex text-sm text-oya-ghana-faded-gray"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      <img
                        src={require("../img/account.png")}
                        alt="account profile"
                        className="pr-2"
                      />
                      Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/security-details"
                      className="flex text-sm pt-8 text-oya-ghana-faded-gray"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      <img
                        src={require("../img/lock.png")}
                        alt="account profile"
                        className="pr-2"
                      />
                      Security
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/verification"
                      className="flex text-sm pt-8 text-oya-ghana-faded-gray"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      <img
                        src={require("../img/check.png")}
                        alt="account profile"
                        className="pr-2"
                      />
                      Verification
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Affix>
        <div className="w-full sm:w-3/4">
          <div className="mt-28 ml-8 mb-8 sm:hidden block">
            <div className="sm:text-oya-ghana-header-h3 leading-[36px] text-oya-ghana-header-h4 font-bold">
              {props.title}
            </div>
            <div className="sm:text-oya-ghana-body-normal-large text-oya-ghana-body-normal text-oya-ghana-faded-gray">
              {props.subTitle}
            </div>
          </div>
          <div className="px-4 sm:px-6 md:px-10 lg:px-12">{props.children}</div>
        </div>
      </div>
    </section>
  );
};
MainLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default MainLayout;
