import { useState } from "react";
import "./style.css";
import Undraw from "../../img/undraw_login.png";
import ForgotPin from "../../components/ForgotPin";
import {
  API_URL,
  COOKIES_TOKEN_NAME,
  COOKIES_USR,
} from "../../helpers/constants";
import { Form, notification, Input } from "antd";
import PropTypes from "prop-types";
import PhoneInput, { Value } from "react-phone-number-input";
import { useStore } from "../../helpers/zustand";
import {
  getDaysUntilJwtExpiration,
  redirect,
  setCookie,
  setCookieJson,
} from "../../helpers/utils";
import { parse } from "querystring";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const Login = () => {
  const userId = Cookies.get(`${COOKIES_USR}`);
  const token = Cookies.get(`${COOKIES_TOKEN_NAME}`);

  const location = useLocation();
  const redirect_url = parse(location.search.replace("?", ""));

  const navigate = useNavigate();

  const [openForgotPin, setForgotPin] = useState(false);
  const [phone, setPhone] = useState<Value | undefined>("");
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [setUserName, setAuthenticated] = useStore((state) => [
    state.setUserName,
    state.setAuthenticated,
  ]);

  const handleSubmit = async () => {
    setLoading(true);
    let formatPhoneNumber = phone?.replace(/\s+/g, "");
    try {
      const jsonData = await axios.post(`${API_URL}/v2/auth/phone-login`, {
        phone: formatPhoneNumber,
        pin,
      });
      const daysUntilExpiration = getDaysUntilJwtExpiration(
        jsonData.data?.payload?.token
      );
      // console.log(jsonData?.data?.payload)

      setCookieJson(
        "usr",
        jsonData.data?.payload?.user?.id,
        daysUntilExpiration
      );
      setCookie("token", jsonData.data?.payload?.token, daysUntilExpiration);
      setCookieJson("user_info", jsonData?.data?.payload?.user)

      setCookieJson(
        "user",
        {
          // staffs: jsonData?.data?.payload?.relations,
          user_roles: jsonData.data?.payload?.user_roles,
          role_id: jsonData.data?.payload?.user?.type?.id,
          role: jsonData.data?.payload?.user?.type?.name,
        },
        daysUntilExpiration
      );

      setAuthenticated(true);
      setUserName(jsonData.data?.payload?.user?.id);
      notification.success({
        message: `Logged in as ${jsonData.data?.payload?.user?.first_name}`,
      });
      if (redirect_url?.appref) {
        //console.log(redirect_url);
        redirect(redirect_url?.appref);
      } else {
        window.location.replace("/");
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      notification.error({ message: error?.response?.data?.message });
    }
  };

  const Loader = ({ color = "text-white" }) => (
    <svg
      className={`animate-spin -ml-1 mr-3 h-5 w-5 ${color}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );

  Loader.propTypes = {
    color: PropTypes.string,
  };

  if (userId && token) {
    return <Navigate to={"/"} />;
  }

  return (
    <>
      <ForgotPin
        isOpen={openForgotPin}
        handleClose={() => {
          setForgotPin(false);
        }}
      />

      <div className="max-w-6xl mx-auto">
        <div className="mb-8 sm:mb-12 md:mb-16 pt-24">
          <div className="flex items-start flex-wrap mb-20 md:gap-0 py-8 sm:pb-12 md:pb-16">
            <div className=" w-full sm:w-1/2 px-4 sm:px-8">
              <div className="login-container p-4 md:p-10 border border-gray-200 shadow-form rounded-xl">
                <div className="text-left">
                  <h1 className="text-oya-ghana-header-h5 md:text-oya-ghana-header-h4 pb-6 font-medium">
                    Welcome
                  </h1>
                  <h3 className="text-oya-ghana-header-h5 pb-5">
                    Sign in to continue traveling with ease
                  </h3>

                  <p className="text-sm pb-5 w-3/5">
                    Enter your phone number and pin to access your account
                  </p>
                </div>

                <Form
                  form={form}
                  onFinish={handleSubmit}
                  layout="vertical"
                  className="w-full"
                  data-testid="login-submit-form"
                >
                  <Form.Item
                    label={
                      <label
                        htmlFor="phone"
                        className="block capitalize text-sm text-gray-601"
                      >
                        Mobile number
                      </label>
                    }
                    name="phone"
                    className="w-full mb-2 pr-3"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                    colon={false}
                  >
                    <PhoneInput
                      id="phone"
                      name="phone"
                      className="phone w-full pl-4 mt-2 mb-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent"
                      defaultCountry="GH"
                      data-testid="login-phone-input"
                      // inputComponent={() => (
                      //   <input className="w-full border focus:outline-none" />
                      // )}
                      onChange={(e) => {
                        setPhone(e);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    colon={false}
                    className="my-4"
                    label={<label
                      htmlFor="pin"
                      className="block capitalize text-sm text-gray-601"
                    >
                      PIN
                    </label>}
                    name="pin"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input.Password
                      data-testid="login-pin-input"
                      type="number"
                      autoComplete="off"
                      size="large"
                      onChange={(e) => {
                        setPin(e.target.value);
                      }}
                      className="w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                    />
                  </Form.Item>
                  <p className="text-sm text-gray-601 pb-4">
                    Did you forget your pin?
                    <button
                      data-testid="forgotpin-btn"
                      className="font-semibold pl-1 pt-2 cursor-pointer disabled:cursor-not-allowed"
                      type="button"
                      onClick={() => {
                        setForgotPin(true);
                      }}
                      disabled={loading}
                    >
                      Reset PIN
                    </button>
                  </p>

                  <button
                    data-testid="login-button"
                    type="submit"
                    disabled={loading}
                    className="flex w-full justify-center rounded-md border border-transparent bg-red-101 py-2 px-4 text-xl font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-101 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-400"
                  >
                    {loading ? <Loader /> : "Sign In"}
                  </button>

                  <p className="mt-2 text-center text-gray-601">
                    Don't have an account?
                    <button
                      data-testid="login-register-link"
                      type="button"
                      onClick={() => {
                        if (redirect_url?.appref) {
                          navigate(`/signup?appref=${redirect_url?.appref}`);
                        } else {
                          navigate(`/signup`);
                        }
                      }}
                      className="font-semibold pl-1 capitalize disabled:cursor-not-allowed"
                      disabled={loading}
                    >
                      Sign up
                    </button>
                  </p>
                </Form>
              </div>
            </div>

            <div className="hidden sm:block w-full sm:w-1/2">
              <div className="sm:pl-10 md:pl-16 lg:pl-24">
                <img
                  src={Undraw}
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                  alt="login-hero"
                />
                <div className="flex flex-col items-start">
                  <h1 className="lg:text-oya-ghana-header-h3 leading-tight text-2xl my-5 font-bold lg:w-[70%] md:[100%]  ">
                    Get started in one, two, three...
                  </h1>
                  <p className="my-2 lg:w-[80%] text-gray-601">
                    A journey by road allows for a greater sense of connection!
                    Your senses record the sights, the breeze and the sheer
                    ambiance only to be played back as memories!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
