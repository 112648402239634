import React from "react";
import { getBase64 } from "../helpers/utils";

const ImageUpload = ({
  uploadImage,
  placeholder,
}: {
  placeholder: string;
  uploadImage: (url: string, base64: any) => void;
}) => {
  const [imageUpload, setImageUpload] = React.useState<File | null>(null);
  const [imageUrl, setImageUrl] = React.useState<string | null>(placeholder);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageUpload(file);

      getBase64(file, (imgUrl: string) => {
        console.log(imageUpload);
        setImageUrl(imgUrl);
        uploadImage(imgUrl, file);
      });
    }
  };

  return (
    <div className="relative border border-solid border-oya-ghana-border mt-2 p-6 rounded-lg bg-oya-ghana-light-gray hover:border-blue-300">
      {imageUrl ? (
        <img src={imageUrl} alt="" />
      ) : (
        <div className="img-upload">
          <div className="text-center flex justify-center py-5">
            <img src={require("../img/Vector.png")} alt="Upload" />
          </div>

          <div className="text-center">
            <p className="pb-2 text-oya-ghana-header-h6 font-semibold">
              Drop your file here or <br /> click to upload
            </p>
            <p className="text-oya-ghana-header-h7 font-bold">
              PNG, JPEG format only. <br /> Maximum file size 5MB.
            </p>
          </div>
        </div>
      )}

      <input
        onChange={handleImageChange}
        data-testid="verification-front-image-input"
        type="file"
        className="absolute inset-0 w-full h-full cursor-pointer"
        style={{ opacity: 0 }}
      />
    </div>
  );
};

export default ImageUpload;
