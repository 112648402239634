import { create } from "zustand";

type State = {
  isAuthenticated: boolean;
  user_name: string | null;
  img_url: string | null;
};

type Action = {
  setAuthenticated: (isAuthenticated: State["isAuthenticated"]) => void;
  setUserName: (user_name: State["user_name"]) => void;
  setImgUrl: (img_url: State["img_url"]) => void;
};

export const useStore = create<State & Action>((set) => ({
  isAuthenticated: false,
  user_name: null,
  img_url: null,
  setAuthenticated: (isAuthenticated) =>
    set(() => ({ isAuthenticated: !isAuthenticated })),
  setUserName: (user_name) => set(() => ({ user_name: user_name })),
  setImgUrl: (img_url) => set(() => ({ img_url: img_url })),
}));
