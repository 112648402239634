import { useState } from "react";
import MainLayout from "../../components/MainLayout";
import { useNavigate, Navigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import cookies from "js-cookie";
import api from "../../helpers/api";
import { COOKIES_USR, COOKIES_TOKEN_NAME } from "../../helpers/constants";
import Skeleton from "react-loading-skeleton";

const Security = () => {
  const userId = cookies.get(`${COOKIES_USR}`);
  const token = cookies.get(`${COOKIES_TOKEN_NAME}`);
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    ice1_phone: "",
    ice2_phone: "",
    phone: "",
    birthdate: "",
    gender: "",
    image: "",
    country: {
      code: "",
      name: "",
      phone_code: "",
    },
  });

  const navigate = useNavigate();

  const { isLoading } = useQuery({
    queryKey: ["user-details"],
    queryFn: async () => {
      const user = await api.get(`/v2/accounts/${userId}`);
      setDetails(user.data?.data);
      return user.data?.data;
    },
  });

  if (userId && token) {
    return (
      <section className="max-w-6xl mx-auto h-full">
        <MainLayout title="Edit PIN" subTitle="You can change personal details">
          <div className="sm:mt-[200px] mb-8">
            <div className="flex items-center flex-wrap w-2/3">
              <div className="capitalize pr-2 text-oya-ghana-header-h5">
                Security details
              </div>
              {isLoading ? (
                <Skeleton />
              ) : (
                <button
                  onClick={() =>
                    navigate(`/security-details/edit/${userId}`, {
                      state: {
                        phone: details.phone,
                      },
                    })
                  }
                >
                  <img src={require("../../img/edit.png")} alt="Edit" />
                </button>
              )}
            </div>
            {isLoading ? (
              <Skeleton count={2} />
            ) : (
              <div className="mt-4">
                <div className="pb-2">PIN</div>
                <div className=" text-2xl font-semibold">***</div>
              </div>
            )}
          </div>
        </MainLayout>
      </section>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default Security;
