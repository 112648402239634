import { useState, useEffect } from "react";
import MainLayout from "../../components/MainLayout";
import { Navigate, useNavigate } from "react-router-dom";
import cookies from "js-cookie";
import { useQuery } from "@tanstack/react-query";
import {
  COOKIES_USR,
  COOKIES_TOKEN_NAME,
  url,
  ACCOUNTS_MS_API_URL,
} from "../../helpers/constants";
import Skeleton from "react-loading-skeleton";
import axios from "axios";

function Verification() {
  const userId = cookies.get(`${COOKIES_USR}`);
  const token = cookies.get(`${COOKIES_TOKEN_NAME}`);
  const navigate = useNavigate();
  //const [verified, setVerified] = useState(false);
  const [details, setDetails] = useState({
    id: 3,
    name: "",
    serial: "",
    front_image_url: "",
    back_image_url: "",
    valid: false,
    verified: false,
    verified_at: null,
    country: {
      code: "GH",
      name: "Ghana",
    },
    issued_at: "2018-06-26",
    expires_at: "2026-10-29",
  });

  const { isLoading, data } = useQuery({
    queryKey: ["verification-details"],
    queryFn: async () => {
      const user = await axios.get(`${ACCOUNTS_MS_API_URL}/v1/identification`);
      //console.log(user.data);
      return user.data;
    },
  });

  useEffect(() => {
    if (data) {
      console.log(data);
      if (data.status === 200) {
        //setVerified(true);
        setDetails(data.data);
      }
    }
  }, [data]);

  if (userId && token) {
    return (
      <section className="max-w-6xl mx-auto h-full">
        <MainLayout title="Verification" subTitle="Get verified">
          {details.serial.length === 0 ? (
            <div className="sm:mt-[200px] mb-8">
              <div className="flex items-center flex-wrap w-2/3">
                <div className="capitalize pr-2 text-3xl">Verification</div>
                {isLoading ? (
                  <Skeleton count={6} />
                ) : (
                  <button
                    onClick={() =>
                      navigate(`/verification/verify/${userId}`, {
                        state: {
                          serial: details.serial,
                        },
                      })
                    }
                  >
                    <img src={require("../../img/edit.png")} alt="Edit" />
                  </button>
                )}
              </div>
              {isLoading ? (
                <Skeleton />
              ) : (
                <div className="capitalize font-medium text-xl mt-8">
                  Verification not uploaded
                </div>
              )}
            </div>
          ) : (
            <div className="sm:mt-[200px] mb-8">
              {isLoading ? (
                <Skeleton count={4} />
              ) : (
                <>
                  <div className="flex items-center flex-wrap w-2/3">
                    <div className="capitalize pr-2 text-3xl">Verification</div>
                    <button
                      onClick={() => navigate(`/verification/verify/${userId}`)}
                    >
                      <img src={require("../../img/edit.png")} alt="Edit" />
                    </button>
                  </div>

                  <div className=" w-full flex flex-wrap mt-8">
                    <div className=" w-[250px] sm:mr-10">
                      <div className="capitalize">
                        Country Membership ID Number
                      </div>
                      <div className=" text-2xl">{details.serial}</div>
                    </div>
                  </div>
                  <div className=" mt-4 w-full flex flex-wrap">
                    <div className="mr-4">
                      <div className="capitalize">Issued Date</div>
                      <div className=" text-2xl">
                        {!details?.issued_at ? "-" : details?.issued_at}
                      </div>
                    </div>
                    <div>
                      <div className="capitalize">Expiry Date</div>
                      <div className=" text-2xl">
                        {!details?.expires_at ? "-" : details?.expires_at}
                      </div>
                    </div>
                  </div>
                  <div className="capitalize font-medium text-xl mt-8">
                    Country Card Images
                  </div>
                  <div className=" w-full flex flex-wrap ">
                    <div className=" w-[250px] sm:mr-10">
                      <div className="pb-2">Card Front Image</div>
                      {details.front_image_url ? (
                        <img src={details.front_image_url} alt="card front" />
                      ) : (
                        <img src={url} alt="card front" />
                      )}
                    </div>
                    <div className="w-[250px]">
                      <div className="pb-2">Card Back Image</div>

                      {details.back_image_url ? (
                        <img src={details.back_image_url} alt="card front" />
                      ) : (
                        <img src={url} alt="card front" />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </MainLayout>
      </section>
    );
  } else {
    return <Navigate to="/login" />;
  }
}

export default Verification;
