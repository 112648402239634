import { useEffect, useState } from "react";
import MainLayout from "../../components/MainLayout";
import { useNavigate } from "react-router-dom";
import { Form, Input, notification } from "antd";
import Loader from "../../components/Loader";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { ACCOUNTS_MS_API_URL } from "../../helpers/constants";
import { useQuery } from "@tanstack/react-query";
import ImageUpload from "../../components/ImageUpload";
import { useStore } from "../../helpers/zustand";

const EditVerificationDetails = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const [cardImages, setCardImage] = useState([
    {
      type: "",
      url: "",
      file: "",
    },
  ]);

  const [user_name] = useStore((state: any) => [state.user_name]);

  const [details, setDetails] = useState({
    id: 3,
    name: "",
    serial: "",
    front_image_url: "",
    back_image_url: "",
    valid: false,
    verified: false,
    verified_at: null,
    country: {
      code: "GH",
      name: "Ghana",
    },
    issued_at: "",
    expires_at: "",
  });

  const { isLoading } = useQuery({
    retry: 1,
    queryKey: ["verification-details-settings"],
    queryFn: async () => {
      const user = await axios.get(`${ACCOUNTS_MS_API_URL}/v1/identification`);
      //console.log(user.data);
      if (user.data?.status === 200) {
        setDetails(user.data?.data);
      }

      return user.data;
    },
  });

  const handleImageUpload = (type: string, url: string, file: any) => {
    const hasNoImgUrlIndex = cardImages?.findIndex((el) => el.url === "");
    if (hasNoImgUrlIndex >= 0) {
      const images = cardImages.slice(0);
      images[hasNoImgUrlIndex] = { type, url, file };
      setCardImage(images);
    }
  };

  const signImage = async (type: string, id: any, cardImg: any) => {
    return new Promise((resolve, reject) => {
      // 2. Upload image mime type
      if (cardImg?.type === "front") {
        return axios
          .post(
            `${ACCOUNTS_MS_API_URL}/v1/identification/image/presigned-upload`,
            {
              mime: cardImg?.file?.type,
            }
          )
          .then((response) => {
            uploadFileToPresignedUrl(
              "front",
              response.data?.data,
              cardImg?.file
            )
              .then(() => {
                // Proceed to save client_url to state
                resolve(response.data?.data);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else if (cardImg?.type === "back") {
        return axios
          .post(
            `${ACCOUNTS_MS_API_URL}/v1/identification/image/presigned-upload`,
            {
              mime: cardImg?.file?.type,
            }
          )
          .then((response) => {
            uploadFileToPresignedUrl("back", response.data?.data, cardImg?.file)
              .then(() => {
                // Proceed to save client_url to state
                resolve(response.data?.data);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };

  const uploadFileToPresignedUrl = async (
    type: string,
    url: any,
    file: any
  ) => {
    if (type === "front") {
      return new Promise((resolve, reject) => {
        return axios
          .put(url?.front?.upload_url, file, {
            headers: {
              "Content-Type": file.type,
            },
          })
          .then(function (response) {
            resolve(JSON.stringify(response.data));
          })
          .catch(function (error) {
            reject(error);
          });
      });
    } else if (type === "back") {
      return new Promise((resolve, reject) => {
        return axios
          .put(url?.back?.upload_url, file, {
            headers: {
              "Content-Type": file.type,
            },
          })
          .then(function (response) {
            resolve(JSON.stringify(response.data));
          })
          .catch(function (error) {
            reject(error);
          });
      });
    }
  };

  const getImageUrls = (id: any) => {
    return new Promise((resolve, reject) => {
      // 1. Get pre-signed url from image url (base64s)
      const promises = cardImages
        ?.filter((_el) => _el.url !== "")
        ?.map(async (el) => {
          try {
            const response = await signImage(el?.type, id, el);
            //const back_res = await signImage(el?.type, id, el);
            return response;
          } catch (e: any) {
            notification.error(e?.message);
          }
        });

      Promise.all(promises)
        .then(
          (res) => {
            setLoading(false);
            resolve(res);
          },
          (error) => {
            setLoading(false);
            reject(error);
          }
        )
        .catch((error) => {
          setLoading(false);
          reject(error);
        });
    });
  };

  const handleSubmit = async (values: any) => {
    //console.log(values);
    setLoading(true);
    setDisabled(true);

    let verificationDetails = {
      name: user_name,
      country_code: "GH",
      serial: values?.serial,
      issued_date: values?.issued_date,
      expiry_date: values?.expiry_date,
    };
    //console.log(verificationDetails);
    try {
      const response = await axios.post(
        `${ACCOUNTS_MS_API_URL}/v1/identification`,
        verificationDetails
      );

      setDisabled(false);
      if (response.data?.status === 200) {
        getImageUrls(response.data?.data?.id)
          .then(() => {
            notification.success({ message: "Identifications uploaded" });
            setLoading(false);
            navigate(-1);
          })
          .catch((e) => {
            notification.error({
              message: e?.message,
            });
          });
      } else {
        console.log(response);
        notification.error({ message: response.data?.message });
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (details) {
      form.setFieldsValue({
        serial: details?.serial,
        issued_date: details?.issued_at,
        expiry_date: details?.expires_at,
        front_image_url: details.front_image_url,
        back_image_url: details.back_image_url,
      });
    }
  }, [details, form]);

  return (
    <section className="max-w-6xl mx-auto h-full">
      <MainLayout title="Verification" subTitle="Get verified">
        <div className="sm:mt-[200px] mb-8">
          <div className="flex items-center flex-wrap w-2/3">
            <div className="capitalize pr-2 text-3xl">Verification</div>
          </div>
        </div>
        <div className="mt-8 w-full">
          {isLoading ? (
            <Skeleton count={6} />
          ) : (
            <>
              <Form
                form={form}
                layout="vertical"
                className="w-full"
                data-testid="edit-verification-submit-form"
                onFinish={handleSubmit}
              >
                <div className="w-full flex items-center flex-wrap">
                  <Form.Item
                    label={
                      <label className="block capitalize text-sm font-semibold">
                        Country Membership ID Number
                      </label>
                    }
                    className="w-1/2 mb-2 pr-3"
                    name="serial"
                    colon={false}
                  >
                    <Input
                      data-testid="edit-verification-country-id-input"
                      type="text"
                      placeholder="GHA-67348994-0"
                      autoComplete="off"
                      value={details.serial}
                      disabled={loading}
                      size="large"
                      onChange={(e) =>
                        setDetails((prevData) => ({
                          ...prevData,
                          serial: e.target.value,
                        }))
                      }
                      className="w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                    />
                  </Form.Item>
                </div>
                <div className="w-full sm:w-1/2 sm:pr-4 mb-3">
                  <Form.Item
                    colon={false}
                    label="Issued Date"
                    name="issued_date"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input
                      type="date"
                      value={details.issued_at}
                      onChange={(e) =>
                        setDetails((prevData) => ({
                          ...prevData,
                          issued_at: e.target.value,
                        }))
                      }
                      className="h-12"
                      autoComplete="off"
                      size="large"
                    />
                  </Form.Item>
                </div>
                <div className="w-full sm:w-1/2 sm:pr-4 mb-3">
                  <Form.Item
                    colon={false}
                    label="Expiry Date"
                    name="expiry_date"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input
                      type="date"
                      value={details.expires_at}
                      onChange={(e) =>
                        setDetails((prevData) => ({
                          ...prevData,
                          expires_at: e.target.value,
                        }))
                      }
                      className="h-12"
                      autoComplete="off"
                      size="large"
                    />
                  </Form.Item>
                </div>
                <div className="capitalize font-medium text-xl mt-4">
                  Country Card Images
                </div>
                <div className="mt-4 w-full flex items-center flex-wrap">
                  <Form.Item
                    name="front_image_url"
                    label={
                      <label className="block capitalize text-sm font-semibold">
                        Card Front Image
                      </label>
                    }
                    className="w-1/2 mb-2 pr-3"
                    colon={false}
                  >
                    <ImageUpload
                      placeholder={details.front_image_url}
                      uploadImage={(url, file) => {
                        // handleSignImage("front", url, file);
                        //uploadSignedImage("front", url, file);
                        handleImageUpload("front", url, file);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="back_image_url"
                    label={
                      <label className="block capitalize text-sm font-semibold">
                        Card Back Image
                      </label>
                    }
                    className="w-1/2 mb-2 pr-3"
                    colon={false}
                  >
                    <ImageUpload
                      placeholder={details.back_image_url}
                      uploadImage={(url, file) => {
                        // handleSignImage("back", url, file);
                        handleImageUpload("back", url, file);
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="w-full flex justify-end items-end mt-6 mb-10">
                  <div className="mr-2">
                    <button
                      disabled={disabled}
                      className="flex rounded-md bg-red-101 text-white capitalize p-3"
                    >
                      {loading && <Loader />}
                      upload
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => navigate("/verification")}
                      className="rounded-md border font-bold capitalize p-3"
                    >
                      cancel
                    </button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </div>
      </MainLayout>
    </section>
  );
};

export default EditVerificationDetails;
