import { useState } from "react";
import MainLayout from "../../components/MainLayout";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Input, notification } from "antd";
import VerifyOTP from "../../components/VerifyOTP";
import Loader from "../../components/Loader";
import { ACCOUNTS_MS_API_URL } from "../../helpers/constants";
import api from "../../helpers/api";

function EditPin() {
  const navigate = useNavigate();
  const location = useLocation();
  const { phone } = location.state;
  // const [userId, setUserId] = useState(cookies.get(`${COOKIES_USER_NAME}`));
  const [pin_details, setPinDetails] = useState({
    phone: phone,
    pin: "",
    new_pin: "",
    confirm_pin: "",
  });
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [modal, showModal] = useState(false);

  const closeModal = () => {
    setLoading(false);
    showModal(false);
    form.resetFields();
  };

  const handleSubmit = async () => {
    //console.log(pin_details);
    if (pin_details.new_pin === pin_details.confirm_pin) {
      setLoading(true);
      try {
        await api.post(`${ACCOUNTS_MS_API_URL}/v1/pin/change`, {
          current: pin_details.pin,
          pin: pin_details.new_pin,
        });
        navigate("/security-details");
      } catch (error: any) {
        console.log(error);
        notification.error({
          message: error?.message || error?.response?.data?.message,
        });
      }

      setLoading(false);
    } else {
      notification.error({ message: "PIN does not match" });
    }
  };

  return (
    <section className="max-w-6xl mx-auto h-full">
      <VerifyOTP
        visible={modal}
        onCancel={closeModal}
        submitting={loading}
        details={pin_details}
      />
      <MainLayout title="Edit PIN" subTitle="You can change personal details">
        <div className="sm:mt-[200px] mb-8">
          <div className="flex items-center flex-wrap w-2/3">
            <div className="capitalize pr-2 text-oya-ghana-header-h5">
              Security details
            </div>
          </div>
          <div className="mt-8 w-full">
            <Form
              form={form}
              layout="vertical"
              className="w-full"
              data-testid="edit-pin-submit-form"
              onFinish={handleSubmit}
            >
              <Form.Item
                label={
                  <label
                    htmlFor="current_pin"
                    className="block text-sm text-gray-601"
                  >
                    Current PIN (Enter a 4 Digit pin)
                  </label>
                }
                className="w-1/2 mb-2 pr-3"
                colon={false}
                rules={[{ message: "This field is required" }]}
                required={true}
              >
                <Input.Password
                  data-testid="edit-pin-current_pin-input"
                  type="number"
                  autoComplete="off"
                  onChange={(e) =>
                    setPinDetails((prevData) => ({
                      ...prevData,
                      pin: e.target.value,
                    }))
                  }
                  size="large"
                  name="current_pin"
                  maxLength={4}
                  className="w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                />
              </Form.Item>
              <div className="w-full flex items-center flex-wrap">
                <Form.Item
                  label={
                    <label
                      htmlFor="new_pin"
                      className="block text-sm text-gray-601"
                    >
                      New PIN (Enter a 4 Digit pin)
                    </label>
                  }
                  rules={[{ message: "This field is required" }]}
                  required={true}
                  className="w-1/2 mb-2 pr-3"
                  colon={false}
                >
                  <Input.Password
                    data-testid="edit-pin-new-pin-input"
                    type="number"
                    onChange={(e) =>
                      setPinDetails((prevData) => ({
                        ...prevData,
                        new_pin: e.target.value,
                      }))
                    }
                    autoComplete="off"
                    size="large"
                    name="new_pin"
                    maxLength={4}
                    className="w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <label
                      htmlFor="confirm_pin"
                      className="block  text-sm text-gray-601"
                    >
                      Confirm PIN (Enter the 4 Digits pin)
                    </label>
                  }
                  className="w-1/2 mb-2 pr-3"
                  colon={false}
                  rules={[{ message: "This field is required" }]}
                  required={true}
                >
                  <Input.Password
                    onChange={(e) =>
                      setPinDetails((prevData) => ({
                        ...prevData,
                        confirm_pin: e.target.value,
                      }))
                    }
                    data-testid="edit-pin-confirm-pin-input"
                    maxLength={4}
                    type="number"
                    autoComplete="off"
                    size="large"
                    name="confirm_pin"
                    className="w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10"
                  />
                </Form.Item>
              </div>
              <div className="w-full flex justify-end items-end mt-6 mb-10">
                <div className="mr-2">
                  <button className="flex rounded-md bg-red-101 text-white capitalize p-3">
                    {loading && <Loader />}
                    update PIN
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => navigate("/security-details")}
                    className="rounded-md border font-bold capitalize p-3"
                  >
                    cancel
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </MainLayout>
    </section>
  );
}

export default EditPin;
