import {
  DB_PREF,
  COOKIES_SECURED,
  COOKIES_DOMAIN,
  COOKIES_TOKEN_NAME,
  COOKIES_USER_NAME,
  COOKIES_USR,
  COOKIES_ROLE,
  COOKIES_SERVICE,
  COOKIES_USER_INFO,
} from "./constants";
import cookie from "js-cookie";

const token = cookie.get("oya_token");

export const app = {
  version: require("../../package.json").version,
  dbpref: "oya_",
  cookieDomain: process.env.REACT_APP_COOKIES_DOMAIN || "localhost",
  dashboard_url: process.env.REACT_APP_DASHBOARD_URL || "http://localhost:3000",
};

export const setCookie = (cname: string, cvalue: any, exdays = 7) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = `${
    DB_PREF + cname
  }=${cvalue};${expires};path=/;domain=${COOKIES_DOMAIN};SameSite=Strict;${
    COOKIES_SECURED ? "Secure" : ""
  }`;
};
export const setCookieJson = (cname: string, cvalue: any, exdays = 7) => {
  setCookie(cname, JSON.stringify(cvalue), exdays);
};

export const getBase64 = (img: any, callback: (params: any) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const formatPhoneNumber = (phone: string) => {
  return phone?.replace(/\s+/g, "");
};

export const redirect = (to: any) => {
  window.location = to;
};

export const handleLogout = (origin?: string | any) => {
  cookie.remove(`${COOKIES_TOKEN_NAME}`, {
    path: "/",
    domain: app.cookieDomain,
    expires: -1,
  });
  cookie.remove(`${COOKIES_USER_NAME}`, {
    path: "/",
    domain: app.cookieDomain,
    expires: -1,
  });
  cookie.remove(`${COOKIES_USR}`, {
    path: "/",
    domain: app.cookieDomain,
    expires: -1,
  });
  cookie.remove(`${COOKIES_ROLE}`, {
    path: "/",
    domain: app.cookieDomain,
    expires: -1,
  });
  cookie.remove(`${COOKIES_SERVICE}`, {
    path: "/",
    domain: app.cookieDomain,
    expires: -1,
  });
  cookie.remove(`${COOKIES_USER_INFO}`, {
    path: "/",
    domain: app.cookieDomain,
    expires: -1,
  });

  window.localStorage.clear();
  if (origin) {
    window.location.href = `/login?appref=${origin}`;
  } else {
    window.location.href = "/login";
  }
};

export const cookieValue = (name: string): string | undefined => {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const [cookieName, cookieVal] = cookie.split("=");
    if (cookieName === name) {
      return cookieVal;
    }
  }
  return undefined;
};

export const setStorage = (key: string, value: string) => {
  if (key && value) {
    localStorage.setItem(DB_PREF + key, value);
  }
};
export const getStorage = (key: string) => {
  const value = localStorage.getItem(DB_PREF + key);
  return value || "";
};

export const config = {
  Authorization: `Bearer ${token}`,
};

export const validateEmail = (
  rule: any,
  value: string,
  callback: (message?: string) => void
) => {
  // Regular expression for basic email validation
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  if (!value || emailRegex.test(value)) {
    callback();
  } else {
    callback("Please enter a valid email address");
  }
};

export const getDaysUntilJwtExpiration = (jwtToken: string) => {
  const tokenParts = jwtToken.split(".");
  if (tokenParts.length !== 3) {
    throw new Error("Invalid JWT format");
  }

  const payload = JSON.parse(atob(tokenParts[1]));

  if (!payload.exp) {
    throw new Error("JWT does not contain an expiration claim (exp)");
  }

  const expirationTimestamp = payload.exp;
  const currentTimestamp = Math.floor(Date.now() / 1000);

  const secondsUntilExpiration = expirationTimestamp - currentTimestamp;
  const daysUntilExpiration = Math.ceil(
    secondsUntilExpiration / (60 * 60 * 24)
  );

  return daysUntilExpiration;
};
export const clearCookie = (name: string, path: string, domain?: string) => {
  var dm = domain || document.domain;
  var pt = path || "/";
  document.cookie =
    name + "=; expires=" + +new Date() + "; domain=" + dm + "; path=" + pt;
};
