import { handleLogout } from '../../helpers/utils';
import { useEffect } from 'react';
import { parse } from 'querystring';
import { useLocation } from 'react-router-dom';

const Logout = () => {
  const location = useLocation();
  const redirect_url = parse(location.search.replace('?', ''));

  useEffect(() => {
    if (redirect_url?.appref) {
      handleLogout(redirect_url?.appref);
    } else {
      handleLogout();
    }
    // eslint-disable-next-line
  }, []);

  return <div>Loading...</div>;
};

export default Logout;
