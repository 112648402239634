import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { handleLogout } from '../helpers/utils';
import Cross from '../img/close-line.png';

function LogoutModal({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <div className='flex items-start justify-between'>
                  <div className='flex items-start'>
                    <h3 className='text-oya-ghana-header-h5 pb-5'>
                      Account Logout
                    </h3>
                  </div>

                  <div
                    className='border-2 border-oya-ghana-border p-2 rounded-full cursor-pointer ml-4'
                    onClick={handleClose}
                  >
                    <img
                      src={Cross}
                      alt='close button'
                      style={{ width: 20, height: 20 }}
                    />
                  </div>
                </div>
                <div className='mt-2'>
                  <p className='text-sm text-gray-500'>
                    You are about to logout. Do you want to continue?
                  </p>
                </div>

                <div className='mt-4 flex gap-2'>
                  <button
                    type='button'
                    className='inline-flex justify-center rounded-md border border-red-101 px-4 py-2 text-sm font-medium text-red-101'
                    onClick={handleClose}
                  >
                    No
                  </button>
                  <button
                    type='button'
                    className='inline-flex justify-center rounded-md bg-red-101 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-red-101 focus-visible:ring-offset-2'
                    onClick={() => handleLogout()}
                  >
                    Yes
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default LogoutModal;
