import React from "react";
import Loader from "../../components/Loader";
import { Modal, Spin, Input, notification } from "antd";
import { API_URL } from "../../helpers/constants";
import { RouteConstants } from "../../helpers/constants";
import api from "../../helpers/api";
import { redirect } from "../../helpers/utils";
import { useStore } from "../../helpers/zustand";

type ModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  phone: string;
  redirect_url: any;
};

const VerifyPhone = ({
  isOpen,
  handleClose,
  phone,
  redirect_url,
}: ModalProps) => {
  const [loading, setLoading] = React.useState(false);
  const [code, setCode] = React.useState<string>("");

  const [setAuthenticated] = useStore((state) => [state.setAuthenticated]);

  const submit = async () => {
    setLoading(true);
    try {
      await api.post(`${API_URL}${RouteConstants.VERIFY_PHONE}`, {
        phone,
        code,
      });

      setAuthenticated(true);
      //setUserName(data?.payload?.user?.id);

      notification.success({
        message: `Registration successful`,
      });
      if (redirect_url?.appref) {
        redirect(redirect_url?.appref);
      } else {
        window.location.replace("/");
      }
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Modal open={isOpen} footer={null} closable={false}>
        <Spin spinning={loading}>
          <div>
            <div className=" text-2xl py-8 lg:w-[316px]">
              Verify your phone number
            </div>

            <div className="mb-1 ml-2">
              Enter the verification code sent to {phone}
            </div>
            <Input
              className="h-[45px] "
              placeholder="Verification code"
              type="number"
              minLength={4}
              onChange={(e) => setCode(e.target.value)}
            />
            <button
              data-testid="register-button"
              onClick={() => {
                submit();
              }}
              disabled={loading}
              className="flex w-full mt-8 justify-center rounded-md border border-transparent bg-red-101 py-2 px-4 text-xl font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-101 focus:ring-offset-2"
            >
              {loading && <Loader />}
              Continue
            </button>
          </div>
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

export default VerifyPhone;
