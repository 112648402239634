import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className='w-full flex items-center flex-wrap justify-center mt-12'>
      <div className=' w-1/3'>
        <div className=' text-9xl font-bold'>404</div>
        <div className='font-bold text-5xl'>Oops!</div>
        <div className='font-bold text-5xl capitalize'>Page not found</div>
        <p className='pt-2 text-xl text-gray-201'>
          This page doesn’t exist or was removed We suggest you go back to Home
        </p>
        <div className='mt-8'>
          <button
            onClick={() => navigate('/')}
            className='capitalize border p-3 border-red-101 text-red-101 rounded-md'
          >
            Go to Homepage
          </button>
        </div>
      </div>
      <div className='h-4/5'>
        <img
          src={require('../img/undraw_blank_canvas.png')}
          alt='blank canvas'
        />
      </div>
    </div>
  );
};

export default ErrorPage;
