import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Loader from "./Loader";
import Cross from "../img/close-line.png";
import { getBase64 } from "../helpers/utils";

const ProfilePhotoUpload = ({
  isOpen,
  handleClose,
  signing,
  loading,
  handleSubmit,
}: {
  isOpen: boolean;
  handleClose: () => void;

  signing: boolean;
  loading: boolean;
  handleSubmit: (file: File | null) => void;
}) => {
  const [imageUpload, setImageUpload] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>("");

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageUpload(file);

      getBase64(file, (imgUrl: string) => {
        //console.log(imageUpload);
        setImageUrl(imgUrl);
        //uploadImage(imgUrl, file);
      });
    }
  };

  return (
    <Transition.Root show={isOpen}>
      <Dialog as="div" className="relative z-[999]" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  {signing ? (
                    <div>
                      ...loading
                      <Loader />
                    </div>
                  ) : (
                    <div className=" mt-3 sm:mt-5">
                      <div className="flex flex-row justify-between items-center w-full">
                        <div className="flex flex-row ">
                          <Dialog.Title
                            as="h3"
                            className="ml-2 text-2xl capitalize font-bold tracking-wide"
                          >
                            Profile photo upload
                          </Dialog.Title>
                        </div>
                        <div>
                          <button
                            onClick={handleClose}
                            className="border rounded-full focus:ring-gray-201 "
                          >
                            <img
                              src={Cross}
                              alt="close button"
                              style={{ width: 30, height: 30 }}
                            />
                          </button>
                        </div>
                      </div>
                      <div className="my-5">
                        {imageUrl && (
                          <img src={imageUrl} alt="Selected Profile" />
                        )}
                        <input
                          onChange={handleImageChange}
                          data-testid="verification-front-image-input"
                          type="file"
                          className="mt-2 inset-0 w-full h-full cursor-pointer"
                          disabled={loading}
                        />
                      </div>
                      {imageUrl && (
                        <div className="w-full flex justify-center items-center mt-6 mb-10 gap-2">
                          <div>
                            <button
                              className="rounded-md flex bg-red-101 text-white capitalize p-3"
                              disabled={loading}
                              onClick={() => {
                                handleSubmit(imageUpload);
                              }}
                            >
                              {loading && <Loader />}
                              update profile photo
                            </button>
                          </div>
                          <div>
                            <button
                              onClick={handleClose}
                              className="rounded-md border font-bold capitalize p-3"
                              disabled={loading}
                              type="button"
                            >
                              cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ProfilePhotoUpload;
