import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/auth/Login";
import Main from "../pages/home/Main";
import Signup from "../pages/auth/Signup";
import Logout from "../pages/auth/Logout";
import EmergencyNum from "../pages/auth/EmergencyNum";
// import { DASHBOARD_URL } from "../helpers/constants";
import EditProfile from "../pages/home/EditProfile";
import ErrorPage from "../pages/ErrorPage";
import Security from "../pages/home/Security";
import Verification from "../pages/home/Verification";
import EditPin from "../pages/home/EditPin";
import EditVerificationDetails from "../pages/home/EditVerificationDetails";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      {/* <Route path={`/${DASHBOARD_URL}` || "/"} element={<Main />} /> */}
      <Route path="/account" element={<Main />} />
      <Route path="/account">
        <Route path="edit-profile/:userId" element={<EditProfile />} />
      </Route>
      <Route path="/security-details" element={<Security />} />
      <Route path="/security-details/edit/:userId" element={<EditPin />} />
      <Route path="/verification" element={<Verification />} />
      <Route
        path="/verification/verify/:userId"
        element={<EditVerificationDetails />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/emergency-number" element={<EmergencyNum />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Router;
