import { useState } from 'react';
import './style.css';
import { Form, Input } from 'antd';
import Undraw from '../../img/undraw_signup.png';
import { useNavigate, useLocation } from 'react-router-dom';
import PhoneInput, { Country, Value } from 'react-phone-number-input';
import Loader from '../../components/Loader';
import { parse } from 'querystring';
import Cookies from 'js-cookie';
import { COOKIES_USR } from '../../helpers/constants';
import { COOKIES_TOKEN_NAME } from '../../helpers/constants';
import { Navigate } from 'react-router-dom';

const Signup = () => {
  const userId = Cookies.get(`${COOKIES_USR}`);
  const token = Cookies.get(`${COOKIES_TOKEN_NAME}`);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const redirect_url = parse(location.search.replace('?', ''));

  const [form] = Form.useForm();
  const [user_data, setUserData] = useState({
    first_name: '',
    last_name: '',
    pin: '',
    confirm_pin: '',
    phone: undefined as Value | undefined,
  });
  const navigate = useNavigate();
  const [code, setCode] = useState<Country | undefined>('GH');

  const handleSubmit = () => {
    setLoading(true);
    //alert(code);
    if (user_data.pin === user_data.confirm_pin) {
      if (redirect_url?.appref) {
        navigate(`/emergency-number?appref=${redirect_url?.appref}`, {
          state: {
            data: user_data,
            code,
            redirect_url: location.state?.appref,
          },
        });
      } else {
        navigate(`/emergency-number`, {
          state: {
            data: user_data,
            code,
            redirect_url: location.state?.appref,
          },
        });
      }
    } else {
      setLoading(false);
      alert('PIN does not match');
    }
  };

  if (userId && token) {
    return <Navigate to={'/'} />;
  }

  return (
    <div className='max-w-6xl mx-auto'>
      <div className='mb-8 sm:mb-12 md:mb-16 pt-24'>
        <div className='flex items-start flex-wrap mb-20 md:gap-0 py-8 sm:pb-12 md:pb-16'>
          <div className=' w-full sm:w-1/2 px-4 sm:px-8'>
            <div className='login-container p-4 md:p-10 border border-gray-200 shadow-form rounded-xl'>
              <div className='text-left'>
                <h3 className='text-oya-ghana-header-h5 font-bold pb-5'>
                  Sign up to start travelling with ease
                </h3>

                <p className='text-sm pb-2'>
                  Provide your details in the form below
                </p>
              </div>
              <Form
                form={form}
                layout='vertical'
                onFinish={handleSubmit}
                data-testid='signup-form-submit'
              >
                <div className='pt-1 w-full flex items-center flex-wrap'>
                  <Form.Item
                    colon={false}
                    label={
                      <label
                        htmlFor='first_name'
                        className='block capitalize text-sm text-gray-601'
                      >
                        First name
                      </label>
                    }
                    className='w-full sm:w-1/2 mb-2 pr-3'
                    name='first_name'
                    rules={[
                      { required: true, message: 'This field is required' },
                      {
                        validator: (_, value) =>
                          !value?.includes(' ')
                            ? Promise.resolve()
                            : Promise.reject(new Error('No spaces allowed')),
                      },
                    ]}
                  >
                    <Input
                      data-testid='register-first-name-input'
                      autoComplete='off'
                      size='large'
                      className='w-full px-4 bg-white border border-gray-200 outline-none rounded-md h-10'
                      disabled={false}
                      onChange={(e) => {
                        setUserData((prevState) => ({
                          ...prevState,
                          first_name: e.target.value,
                        }));
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    label={
                      <label
                        htmlFor='first_name'
                        className='block capitalize text-sm text-gray-601'
                      >
                        Last name
                      </label>
                    }
                    className='w-full sm:w-1/2 mb-2 pr-3'
                    name='last_name'
                    rules={[
                      { required: true, message: 'This field is required' },
                      {
                        validator: (_, value) =>
                          !value?.includes(' ')
                            ? Promise.resolve()
                            : Promise.reject(new Error('No spaces allowed')),
                      },
                    ]}
                  >
                    <Input
                      data-testid='register-last-name-input'
                      autoComplete='off'
                      size='large'
                      className='w-full px-4 bg-white border border-gray-200 outline-none rounded-md h-10'
                      disabled={false}
                      onChange={(e) =>
                        setUserData((prevState) => ({
                          ...prevState,
                          last_name: e.target.value,
                        }))
                      }
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label={
                      <label
                        htmlFor='phone'
                        className='block capitalize text-sm text-gray-601'
                      >
                        Phone number
                      </label>
                    }
                    name='phone'
                    className='w-full mb-2 pr-3'
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                    colon={false}
                    required={true}
                  >
                    <PhoneInput
                      id='phone'
                      className='phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none'
                      data-testid='register-phone-input'
                      defaultCountry={code}
                      value={user_data.phone}
                      rules={{
                        required: true,
                      }}
                      onChange={(e) => {
                        let formatted = e?.replace(/\s+/g, '');
                        setUserData((prevData) => ({
                          ...prevData,
                          phone: formatted,
                        }));
                      }}
                      onCountryChange={(e) => setCode(e)}
                    />
                  </Form.Item>
                </div>
                <div className='w-full flex items-center flex-wrap'>
                  <Form.Item
                    colon={false}
                    label={
                      <label
                        htmlFor='pin1'
                        className='block text-sm text-gray-601'
                      >
                        PIN(Enter a 4 digit pin)
                      </label>
                    }
                    className='w-full sm:w-1/2 mb-2 pr-3'
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <Input.Password
                      name='pin1'
                      type='password'
                      autoComplete='off'
                      size='large'
                      className='w-full px-4 mt-2 mb-2 border border-gray-200 rounded-md outline-none h-10'
                      maxLength={4}
                      onChange={(e) =>
                        setUserData((prevState) => ({
                          ...prevState,
                          pin: e.target.value,
                        }))
                      }
                      data-testid='register-pin-input'
                    />
                  </Form.Item>
                  <Form.Item
                    colon={false}
                    label={
                      <label
                        htmlFor='new_pin'
                        className='block text-sm text-gray-601'
                      >
                        Confirm PIN
                      </label>
                    }
                    name='pin2'
                    className='w-full sm:w-1/2 mb-2 pr-3'
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <Input.Password
                      type='password'
                      autoComplete='off'
                      name='pin2'
                      size='large'
                      className='w-full px-4 mt-2 mb-2 border border-gray-200 rounded-md outline-none h-10'
                      maxLength={4}
                      onChange={(e) =>
                        setUserData((prevState) => ({
                          ...prevState,
                          confirm_pin: e.target.value,
                        }))
                      }
                      data-testid='register-confirm-pin-input'
                    />
                  </Form.Item>
                </div>
                <button
                  data-testid='signup-button'
                  type='submit'
                  className='flex w-full justify-center rounded-md border border-transparent bg-red-101 py-2 px-4 text-xl font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-101 focus:ring-offset-2'
                >
                  {loading ? <Loader /> : "Continue"}

                </button>
              </Form>

              {/* <div className="pt-8">
              
            </div> */}
              {/* <div className="pt-2">
                
              </div> */}
              <div className='pt-4 flex justify-center items-center'>
                <div className='text-sm'>
                  <span>Already have an account?</span>
                  <button
                    onClick={() => {
                      if (redirect_url?.appref) {
                        navigate(`/login?appref=${redirect_url?.appref}`);
                        //redirect(app.accounts_url + "login" + "?appref=" + location.state?.appref + "/")
                      } else {
                        navigate('/login');
                      }
                    }}
                    className='capitalize font-black text-gray-401 hover:text-red-500 pl-1'
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='hidden sm:block w-full sm:w-1/2'>
            <div className='sm:pl-10 md:pl-16 lg:pl-24'>
              <img
                src={Undraw}
                alt='Secure sign-in'
                className='md:w-[320px] h-[auto] max-[300px]:w-full'
              />
            </div>
            <div className='max-[300px]:w-[350px] pt-8 font-bold text-3xl'>
              Get Started in one, two, three ...
            </div>
            <p className='max-[300px]:w-[350px] pt-4 text-base opacity-60'>
              A journey by road allows for a greater sense of connection! Your
              senses record the sights, the breeze and the sheer ambiance only
              to be played back as memories!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
